<template>
  <header class="menubar-parent">
    <!-- MENUBAR -->
    <div class="menubar">
      <!-- ICON -->
      <i class="fa fa-clock-o"></i>

      <!-- SYSTEM CLOCK -->
      <time
        class="system-clock"
        v-text="dateString"
        :datetime="Date.now()"
      >
      </time>
    </div>
  </header>
</template>

<script>
  // https://jsfiddle.net/m7e2xcf0/1/
  import moment from "moment";

  export default {
    data() {
      return {
        animationFrame: null,
        dateTime: Date.now(),
      };
    },
    created() {
      this.updateDateTime();
    },
    beforeDestroy() {
      cancelAnimationFrame(this.animationFrame);
    },
    computed: {
      dateString() {
        return moment(this.dateTime).format("ddd h:mm a");
        // return moment(this.dateTime).format("ddd, DD MMM YYYY - h:mm a");
      },
    },
    methods: {
      updateDateTime() {
        this.dateTime = new Date();
        this.animationFrame = requestAnimationFrame(this.updateDateTime);
      },
    },
  };
</script>

<style lang="scss" scoped>
  //---------------------------------------------------------
  // MENUBAR
  //---------------------------------------------------------

  .menubar-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    // height: rem-calc(35); // 30
    // background: rgba(black, 0.2);
    user-select: none;
    z-index: 3;
  }

  .menubar {
    margin-top: rem-calc(10);
    font-size: rem-calc(18);
    font-weight: 600;
    color: lighten(#cccccc, 10%); //darken(white, 5%);
    background: rgba(black, 0.2);
    padding: rem-calc(5 13);
    // border-radius: 0 0 5px 5px;
    border-radius: 30px;
  }

  .menubar .fa-clock-o {
    opacity: 0.6;
    margin-right: rem-calc(8);
  }
</style>
